<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa sopimuspohjaa</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-card class="mt-2">
      <v-container class="container-wrapper">
        <contract-model-form
          :model="model"
          save-btn-text="Tallenna"
          @sendcontractmodeldata="updateModel($event)"
        ></contract-model-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import ContractModelForm from "../../components/ContractModels/ContractModelForm";

export default {
  title: "Muokkaa vuokrasopimuspohjaa",

  components: {
    ContractModelForm,
  },

  mixins: [mixins],

  data() {
    return {
      model: {
        name: "",
        content: "",
      },
    };
  },

  async created() {
    try {
      let res = await axiosMethods.get("/api/v1/contract-model/getOne/" + this.$route.params.id);
      this.model = res.data.data;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    async updateModel(data) {
      try {
        await axiosMethods.update("/api/v1/contract-model/update/" + this.$route.params.id, data);
        this.showPopup("Sopimuspohjaa muokattu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .container-wrapper {
    margin: 0;
    padding: 0;
  }
}
</style>
