<template>
  <v-form ref="form">
    <div class="editor">
      <v-row dense>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="modelName"
            :rules="required"
            outlined
            dense
            hide-details
            label="Sopimuksen nimi"
          >
            Nimi
          </v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-radio-group
            v-model="modelType"
            @change="loadNewTemplate"
            :mandatory="true"
            style="margin-top: 0px"
          >
            <v-radio
              @click.capture="modelTypeChange"
              label="Vuokrasopimus"
              value="rentalContract"
            ></v-radio>
            <v-radio
              @click.capture="modelTypeChange"
              label="Muu sopimus"
              value="otherContract"
            ></v-radio>
            <v-radio
              @click.capture="modelTypeChange"
              label="Vuokrankorotus"
              value="rentIncrease"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <div v-if="modelType">
        <v-row dense>
          <v-col>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>Ohjeistus</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>
                    1. Varmista, että kaikki alasvetovalikosta valittavat "toiminnot" ovat omilla
                    riveillään ja "Paragraph" tagilla muotoiltuja (oletus), jotta editorista
                    muodostuva sopimus tulostuu pdf:lle tai sopimuksen yhteenvedolle oikein.
                  </p>
                  <p>
                    2. Jokainen ehtolause on suljettava ehtolauseen alkua vastaavalla loppu-tagillä
                    (#Loppu tai &Loppu). Ehtolauseen alku (# tai &) kertoo kumpaa lopputagia on
                    käytettävä.
                  </p>
                  <p>
                    3. Muuttujien, eli sopimukseen syötettujen arvojen asettelun, sijainnin tai
                    muotoilun voi valita vapaasti.
                  </p>
                  <p>4. Tekstien tasaaminen vaatii valittavien tekstien maalaamisen hiirellä.</p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <!-- MODEL EDITOR -->
        <v-row dense>
          <v-col cols="12" class="pt-0 pb-0">
            <model-editor
              :modelType="modelType"
              :value="modelContentLoaded"
              @input="(content) => handlerContentChange(content)"
            />
          </v-col>
        </v-row>
        <v-btn color="info" class="mr-4 mt-5" @click="sendModel">{{ saveBtnText }}</v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import ModelEditor from "./ModelEditor";
import mixins from "../../mixins/mixins";
import config from "@/configs";

export default {
  components: {
    ModelEditor,
  },

  mixins: [mixins],

  props: {
    saveBtnText: {
      type: String,
      default: "",
    },

    model: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      modelName: "",
      modelType: "",
      modelContentLoaded: "",
      modelContentMutated: "",
      modelContentRentalContract: config.contract.defaultRentalContract,
      modelContentOtherContract: config.contract.defaultOtherContract,
      modelContentRentIncrease: config.contract.defaultRentIncrease,
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  created() {
    // at start
    this.modelName = this.model.name;
    this.modelType = this.model.modelType;
    this.modelContentLoaded = this.model.content;
    this.modelContentMutated = this.model.content;
  },

  watch: {
    model(val) {
      if (val) {
        this.modelName = val.name;
        this.modelType = val.modelType;
        this.modelContentLoaded = val.content;
      }
    },
  },

  methods: {
    async sendModel() {
      if (this.$refs.form.validate()) {
        const modelTemplate = {
          name: this.modelName,
          modelType: this.modelType,
          content: this.modelContentMutated,
        };
        this.$emit("sendcontractmodeldata", modelTemplate);
      } else {
        this.showPopup("Jokin pakollisista kentistä ei ole täydennetty.", "error");
      }
    },

    modelTypeChange(event) {
      if (
        !confirm(
          "Olet muuttamassa sopimuksen tyyppiä. Sisältö täytyy ladata uudelleen. Haluatko jatkaa?"
        )
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    loadNewTemplate() {
      if (this.modelType == "rentalContract") {
        this.modelContentLoaded = this.modelContentRentalContract;
        this.modelContentMutated = this.modelContentRentalContract;
      } else if (this.modelType == "otherContract") {
        this.modelContentLoaded = this.modelContentOtherContract;
        this.modelContentMutated = this.modelContentOtherContract;
      } else {
        this.modelContentLoaded = this.modelContentRentIncrease;
        this.modelContentMutated = this.modelContentRentIncrease;
      }
    },

    handlerContentChange(content) {
      this.modelContentMutated = content;
    },
  },
};
</script>

<style scoped>
@import "~quill-placeholder-module/dist/toolbar.css";

.warning-sign p {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(231, 74, 74);
  width: 100%;
  min-height: 50px;
  font-size: 14px;
  padding: 25px 25px 25px 25px;
  align-items: center;
  color: white;
}

div.editor {
  background-color: rgb(245, 245, 245);
  padding: 10px;
  margin-top: 10px;
}

.ql-picker.ql-placeholder > span.ql-picker-label::before {
  content: "Placeholders";
}
</style>
